import { FormStepProps } from '../shared/types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import React from 'react';

const { TextArea } = Input;

const DescriptionStep = ({ event, disabled, readonly }: FormStepProps) => {
  return (
    <>
      {/*
      // @ts-ignore */}
      <Form.Item colon={false} label="Title">
        <Input
          disabled={disabled}
          readOnly={readonly}
          defaultValue={event.title ?? ''}
        />
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item colon={false} label="Relevant text fields - Description">
        <TextArea
          autoSize={{
            minRows: 5,
            maxRows: 5,
          }}
          disabled={disabled}
          readOnly={readonly}
          defaultValue={event.relevantTextFields.eventGroupDescription ?? ''}
        />
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item colon={false} label="Relevant text fields - Subtitle">
        <TextArea
          autoSize={{
            minRows: 5,
            maxRows: 5,
          }}
          disabled={disabled}
          readOnly={readonly}
          defaultValue={event.relevantTextFields.subtitle ?? ''}
        />
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item colon={false} label="Other text fields">
        <TextArea
          autoSize={{
            minRows: 5,
            maxRows: 5,
          }}
          disabled={disabled}
          readOnly={readonly}
          defaultValue={event.otherTextFields.description ?? ''}
        />
      </Form.Item>
    </>
  );
};

export default DescriptionStep;
