import { useNavigate } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import { routes } from '../../route-urls';
import { SpinLoader } from '../../components/common/spin-loader/spin-loader.component';

const StyledSpinLoader = styled(SpinLoader)`
  height: calc(100vh);
`;

const PostLoginPage = () => {
  const navigate = useNavigate();
  const timeout = React.useRef<NodeJS.Timeout | undefined>(undefined);

  const redirect = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const postLoginRedirectUrl = urlParams.get('post_login_redirect');
    if (
      postLoginRedirectUrl === null ||
      postLoginRedirectUrl === routes.postLogin()
    ) {
      navigate(routes.brandedContainers(), {
        state: { from: routes.postLogin() },
      });
    } else {
      navigate(postLoginRedirectUrl, { state: { from: routes.postLogin() } });
    }
  };

  if (!timeout.current) {
    timeout.current = setTimeout(redirect, 100);
  }

  return <StyledSpinLoader loadingMessage="Loading data..." />;
};

export default PostLoginPage;
