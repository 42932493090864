import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AvailablePageSizes, Entity, PaginationType } from '../types';
import { Order, Pagination } from '../../../../resolver.types';
import { last } from 'yggdrasil-shared/utils/common';
import {
  HitData,
  HitDataRecord,
} from 'yggdrasil-shared/database/opensearch-views';
import usePrevious from '../../../../hooks/use-previous';
import { DoubleLeftOutlined } from '@ant-design/icons';
import { Button, Row, Tooltip } from 'antd';
import { TablePaginationConfig } from 'antd/es/table/interface';

export const defaultPageSizes: AvailablePageSizes = [10, 20, 50, 100];

export type PaginationHookConfig<T extends Entity> = {
  availablePageSizes?: number[];
  totalCount?: number;
  paginationType?: PaginationType;
  data?: T[];
  hitData?: HitDataRecord;
  order?: Order;
  onGoToFirstPage?: (pagination: Pagination) => any;
  defaultPagination?: Pagination;
};

const usePagination = <T extends Entity = any>({
  availablePageSizes = defaultPageSizes,
  totalCount = 0,
  paginationType = 'skip',
  data = [],
  hitData = {},
  onGoToFirstPage,
  defaultPagination = {
    start: 0,
    limit: availablePageSizes[0],
  },
}: PaginationHookConfig<T> = {}) => {
  const [previousOrder, setPreviousOrder] = useState<Order | undefined>();

  const [hitDataHistory, setHitDataHistory] = useState<HitData[]>([]);
  const [paginationValue, setPaginationValue] = useState<TablePaginationConfig>(
    {}
  );
  const [lastDataItem, setLastDataItem] = useState<T | null>(null);
  const { current: page = 1 } = paginationValue;
  const previousPage = usePrevious(page);

  const [pagination, setPagination] = useState<Pagination>(defaultPagination);
  const previousPagination = usePrevious(pagination);

  const parsedPagination: TablePaginationConfig = useMemo(
    () => ({
      ...pagination,
      defaultPageSize: availablePageSizes[0],
      current: page,
      total: totalCount,
      pageSizeOptions: availablePageSizes.map((n) => n.toString()),
      showSizeChanger: availablePageSizes.length > 1,
      showTotal:
        paginationType === 'skip'
          ? undefined
          : (total, [start, end]) => (
              <small>
                Displaying {start}-{end} from {total}
              </small>
            ),
      position: ['bottomCenter', 'topCenter'],
      itemRender: (page, type, originalElement) => {
        if (paginationType === 'skip') {
          return originalElement;
        }

        if (!['prev', 'next'].includes(type)) {
          return null;
        }

        return (
          <Row style={{ display: 'flex' }}>
            {type === 'prev' && page > 0 && (
              <Tooltip title="Return to first page">
                <Button
                  className="prev-all"
                  onClick={
                    onGoToFirstPage
                      ? (event) => {
                          event.stopPropagation();

                          onGoToFirstPage(pagination);
                        }
                      : undefined
                  }
                  type="link"
                  icon={<DoubleLeftOutlined />}
                />
              </Tooltip>
            )}
            <Tooltip
              title={`Go to ${type === 'prev' ? 'previous' : 'next'} page`}
            >
              {originalElement}
            </Tooltip>
          </Row>
        );
      },
      className: `pagination-${paginationType}`,
    }),
    [
      pagination,
      totalCount,
      availablePageSizes,
      page,
      onGoToFirstPage,
      paginationType,
    ]
  );

  useEffect(() => {
    if (data.length > 0) {
      setLastDataItem(last(data));
    } else {
      setLastDataItem(null);
    }
  }, [data]);

  useEffect(() => {
    if (page === previousPage || !lastDataItem?.id) {
      return;
    }

    const newDataHistory = [...hitDataHistory];
    newDataHistory[page] = hitData[lastDataItem.id];

    setHitDataHistory(newDataHistory);
  }, [page, previousPage, hitData, lastDataItem, hitDataHistory]);

  const handlePaginationChange = useCallback(
    (pagination: TablePaginationConfig, order?: Order) => {
      if (!Object.values(hitData).length && paginationType === 'searchAfter') {
        throw new Error(
          'Hit data is missing in "usePagination" hook used in datalist. When pagination type is set to "searchAfter" you must provide hitData in query result.'
        );
      }

      const newPaginationValue = {
        ...pagination,
        current:
          paginationType === 'skip'
            ? pagination.current
            : order === previousOrder ||
              pagination.current !== paginationValue.current
            ? pagination.current
            : 1,
      };

      const { current: page = 1, pageSize = availablePageSizes[0] } =
        newPaginationValue;

      const getPagination = () => {
        const skip = () => ({
          start: (page - 1) * pageSize,
          limit: pageSize,
        });

        switch (paginationType) {
          case 'searchAfter': {
            const isReturning = previousPage && previousPage > page;

            if (!lastDataItem) {
              return skip();
            }

            const getSearchAfter = () => {
              if (page <= 1) {
                return null;
              }

              if (isReturning) {
                return hitDataHistory[page]?.sort ?? null;
              }

              return hitData[lastDataItem.id]?.sort ?? null;
            };

            return {
              limit: pageSize,
              searchAfter: getSearchAfter(),
            };
          }

          case 'skip':
          default:
            return skip();
        }
      };

      const newPagination = getPagination();

      setPaginationValue(newPaginationValue);
      setPagination(newPagination);

      setPreviousOrder(order);

      return {
        paginationValue: newPaginationValue,
        pagination: newPagination,
      };
    },
    [
      paginationValue,
      availablePageSizes,
      previousOrder,
      hitData,
      hitDataHistory,
      lastDataItem,
      paginationType,
      previousPage,
    ]
  );

  return {
    pagination,
    paginationValue,
    setPaginationValue,
    parsedPagination,
    previousPagination,
    handlePaginationChange,
  };
};

export default usePagination;
