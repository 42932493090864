import { Column } from '../components/common/data-list/types';
import { StateSwitcher } from '../components/branded-container/state-switcher/state-switcher.component';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { default as React, useMemo } from 'react';
import { routes } from '../route-urls';
import { BrandedContainer } from '../resolver.types';
import { DateFormats } from '../utils/date-formats';
import Title from '../components/branded-container/title/title.component';
import LastSuggestedEventsUpdateDate from '../components/branded-container/last-suggested-events-update-date/last-suggested-events-update-date.component';
import useAppContext from './use-app-context';
import BrandedContainerLink from '../components/branded-container/branded-container-link/branded-container-link.component';
import { EventsType } from '../components/event/events-switcher/events-switcher.component';
import { getSuggestedEventsCount } from '../components/event/suggested-events-count/suggested-events-count.component';

export type BrandedContainerColumnsHookConfig = {
  containerUrlCreator?: (containerID: string, content?: string) => string;
  showBrands?: boolean;
  onClick?: (brandedContainer: BrandedContainer) => void;
  disabled?: boolean;
};

const useBrandedContainerColumns = ({
  onClick,
  containerUrlCreator = routes.setBrandedContainerData,
  showBrands = false,
  disabled,
}: BrandedContainerColumnsHookConfig = {}) => {
  const {
    state: { selectedBrand },
  } = useAppContext();

  const getTitleComponent = useMemo(
    () => (brandedContainer: BrandedContainer) =>
      (
        <Title
          thumbnailProps={{
            showBrand: showBrands,
            brandSource: 'brandedContainer',
          }}
          brandedContainer={brandedContainer}
          onClick={() => !disabled && onClick && onClick(brandedContainer)}
        />
      ),
    [onClick, showBrands, disabled]
  );

  const getTitleWrapper = useMemo(
    () => (brandedContainer: BrandedContainer) =>
      !onClick ? (
        <BrandedContainerLink
          noPadding
          urlCreator={containerUrlCreator}
          brandedContainer={brandedContainer}
        >
          {getTitleComponent(brandedContainer)}
        </BrandedContainerLink>
      ) : (
        <div
          style={{
            padding: '16px',
            minHeight: '60px',
            width: '100%',
            display: 'block',
          }}
        >
          {getTitleComponent(brandedContainer)}
        </div>
      ),
    [onClick, containerUrlCreator, getTitleComponent]
  );

  return useMemo<Array<Column<BrandedContainer>>>(
    () => [
      {
        title: 'Branded containers ({count})',
        dataIndex: 'title',
        render: (_, brandedContainer) => {
          return getTitleWrapper(brandedContainer);
        },
      },
      {
        title: 'Branded container title',
        dataIndex: 'title',
        render: (text, record) => {
          return (
            <BrandedContainerLink
              noPadding
              urlCreator={containerUrlCreator}
              brandedContainer={record}
            >
              {record.title ?? '-'}
            </BrandedContainerLink>
          );
        },
      },
      {
        title: 'Publishing state',
        dataIndex: 'state',
        render: (_, record) => (
          <StateSwitcher
            disabled={selectedBrand?.name !== record.brand}
            refetchQueriesOnUpdate={false}
            brandedContainer={record}
          />
        ),
        sortable: true,
      },
      {
        title: 'Events assigned',
        render: (_, record) => (
          <BrandedContainerLink
            noPadding
            urlCreator={containerUrlCreator}
            brandedContainer={record}
          >
            {record.eventIDs?.length ?? 0}
          </BrandedContainerLink>
        ),
      },
      {
        title: 'Events suggestions',
        dataIndex: 'suggestedEventIDs',
        sortable: true,
        render: (_, record) => {
          if (!record.suggestedEventIDs?.length) {
            return (
              <BrandedContainerLink
                noPadding
                urlCreator={containerUrlCreator}
                brandedContainer={record}
              >
                <span>
                  <span style={{ marginRight: '5px' }}>0</span>
                  <LastSuggestedEventsUpdateDate
                    date={record.lastSuggestedEventsUpdate}
                  />
                </span>
              </BrandedContainerLink>
            );
          }

          return (
            <BrandedContainerLink
              noPadding
              urlCreator={containerUrlCreator}
              brandedContainer={record}
              content="bc-events"
              state={{
                tab: EventsType.Suggested,
              }}
            >
              <span>
                <span
                  style={{ textDecoration: 'underline', marginRight: '5px' }}
                  className="text--primary"
                >
                  {getSuggestedEventsCount(
                    record.suggestedEventsTotal ??
                      record.suggestedEventIDs.length,
                    true
                  )}
                </span>
                <LastSuggestedEventsUpdateDate
                  date={record.lastSuggestedEventsUpdate}
                />
              </span>
            </BrandedContainerLink>
          );
        },
      },
      {
        title: 'Importance',
        render: (_, record) => (
          <BrandedContainerLink
            noPadding
            brandedContainer={record}
            urlCreator={containerUrlCreator}
          >
            {record.relevance ?? 0}/10
          </BrandedContainerLink>
        ),
        sortable: true,
        dataIndex: 'relevance',
      },
      {
        title: 'Last modified',
        dataIndex: 'updatedAt',
        render: (_, record) => (
          <BrandedContainerLink
            noPadding
            brandedContainer={record}
            urlCreator={containerUrlCreator}
          >
            {record.lastUpdatedAuthor && record.updatedAt ? (
              <div>
                <div>
                  On {format(parseISO(record.updatedAt), DateFormats.dateTime)}
                </div>
                <div>{record.lastUpdatedAuthor ?? record.createdBy}</div>
              </div>
            ) : (
              <div>-</div>
            )}
          </BrandedContainerLink>
        ),
        sortable: true,
      },
    ],
    [containerUrlCreator, selectedBrand, getTitleWrapper]
  );
};

export default useBrandedContainerColumns;
