import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  DrawerTopBar,
  TopicResult,
} from '../drawer-topbar/drawer-topbar.component';
import { BrandedContainerDrawerContent } from '../drawer-content/drawer-content.component';
import { StyledDrawer } from './drawer.styles';
import { SpinLoader } from '../../common/spin-loader/spin-loader.component';
import {
  BrandedContainerState,
  GetBrandedContainerTopicDataDocument,
  GetBrandedContainerTopicDataQuery,
  BrandedContainerThumbnailType,
  GetBrandedContainerDocument,
  GetActivityForBrandedContainerQueryResult,
  GetActivityForBrandedContainerQueryVariables,
  GetActivityForBrandedContainerDocument,
} from '../../../resolver.types';
import { useMutation } from '@apollo/client';
import { Button, message, Modal, Result } from 'antd';
import {
  UPDATE_BRANDED_CONTAINER,
  UpdateBrandedContainerResult,
  UpdateBrandedContainerVariables,
} from '../../../graphql/mutations/update-branded-container';
import { useCallback, useEffect, useState } from 'react';
import useAppContext from '../../../hooks/use-app-context';
import { BrandedContainerDetailsAction } from '../drawer-content/shared/types';
import { BrandName } from 'yggdrasil-shared/domain/dictionary';
import debounce from 'lodash.debounce';

export type BrandedContainerDrawerProps = {
  id: string;
  onClose: () => void;
  isNestedDrawer?: boolean;
  width?: number;
  actions?: BrandedContainerDetailsAction[];
};

export const BrandedContainerDrawer = ({
  id: brandedContainerId,
  onClose: closeCallback,
  isNestedDrawer = false,
  width,
  actions,
}: BrandedContainerDrawerProps) => {
  const {
    state: { selectedBrand, activeBrandTab },
    dispatch,
  } = useAppContext();

  const onBrandChange = useCallback(
    (brandName: string) =>
      dispatch({
        type: 'SET_ACTIVE_BRAND_TAB',
        activeBrandTab: brandName as BrandName,
      }),
    [dispatch]
  );

  const [topic, setTopic] = useState<TopicResult | null>(null);
  const [isDirty, setIsDirty] = useState(false);

  const [update, { error, loading }] = useMutation<
    UpdateBrandedContainerResult,
    UpdateBrandedContainerVariables
  >(UPDATE_BRANDED_CONTAINER, {
    refetchQueries: [
      {
        query: GetBrandedContainerDocument,
        variables: {
          id: brandedContainerId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const {
    data,
    refetch,
    error: queryError,
  } = useQuery<GetBrandedContainerTopicDataQuery>(
    GetBrandedContainerTopicDataDocument,
    {
      variables: {
        id: brandedContainerId,
      },
      fetchPolicy: 'network-only',
    }
  );

  const { data: activityLogData, refetch: refetchActivityLog } = useQuery<
    GetActivityForBrandedContainerQueryResult['data'],
    GetActivityForBrandedContainerQueryVariables
  >(GetActivityForBrandedContainerDocument, {
    variables: {
      id: brandedContainerId,
      pagination: {
        start: 0,
        limit: 3,
      },
    },
    fetchPolicy: 'network-only',
  });

  const onChangeRelevance = debounce(async (relevance: number) => {
    if (!data) {
      return;
    }

    await update({
      variables: {
        brandedContainer: {
          id: data.brandedContainer!.id,
          version: data.brandedContainer!.version,
          relevance,
        },
      },
    });
  }, 1000);

  const onIndexStateChange = async (indexState: boolean) => {
    if (!data) {
      return;
    }

    await update({
      variables: {
        brandedContainer: {
          id: data.brandedContainer!.id,
          version: data.brandedContainer!.version,
          isIndexable: indexState,
        },
      },
    });

    refetchActivityLog();
  };

  const handleClose = useCallback(() => {
    if (!isDirty) {
      closeCallback();

      return;
    }

    Modal.confirm({
      className: 'modal-no-title',
      title: '',
      content: 'You have unsaved changes, are you sure you want to continue?',
      cancelText: 'Continue without saving',
      okText: 'Keep editing',
      onCancel: closeCallback,
    });
  }, [isDirty, closeCallback]);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (data && data.brandedContainer) {
      setTopic(data.brandedContainer.topic as TopicResult);
    } else {
      timeout = setTimeout(
        () =>
          refetch({
            id: brandedContainerId,
          }),
        1000
      );
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [data, refetch, brandedContainerId]);

  useEffect(() => {
    if (data?.brandedContainer) {
      onBrandChange(data.brandedContainer.brand);
      // setBrandedContainerBrand(data.brandedContainer.brand);
    }
  }, [data, onBrandChange]);

  const isFormEnabled = React.useMemo(() => {
    const editableStates = [
      BrandedContainerState.Draft,
      BrandedContainerState.Published,
    ];

    return (
      !isNestedDrawer &&
      data?.brandedContainer &&
      editableStates.includes(data.brandedContainer.state) &&
      activeBrandTab === selectedBrand?.name
    );
  }, [data, selectedBrand, activeBrandTab, isNestedDrawer]);

  const isTopbarEnabled = React.useMemo(() => {
    return (
      !isNestedDrawer &&
      data?.brandedContainer &&
      data.brandedContainer.state !== BrandedContainerState.Deleted &&
      activeBrandTab === selectedBrand?.name
    );
  }, [data, selectedBrand, activeBrandTab, isNestedDrawer]);

  return (
    <StyledDrawer
      visible
      width={width || 1050}
      push={false}
      bodyStyle={{
        padding: 0,
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
      onClose={handleClose}
    >
      {queryError && (
        <Result
          style={{
            marginTop: '25%',
          }}
          status="error"
          extra={[
            <Button key={1} onClick={closeCallback}>
              Close
            </Button>,
          ]}
          title={queryError.message}
        />
      )}
      {!queryError && (
        <>
          {!topic || !data ? (
            <SpinLoader />
          ) : (
            <>
              <DrawerTopBar
                disabled={!isTopbarEnabled}
                isFormDisabled={!isFormEnabled}
                updateLoading={loading}
                isDirty={isDirty}
                relevance={data?.brandedContainer?.relevance ?? 0}
                brandedContainerId={brandedContainerId}
                topic={topic}
                onChangeRelevance={onChangeRelevance}
                onStateChange={() => {
                  refetchActivityLog();
                }}
                onIndexStateChange={onIndexStateChange}
                thumbnailUrl={
                  data?.brandedContainer?.thumbnails?.find(
                    (thumbnail) =>
                      thumbnail.type === BrandedContainerThumbnailType.A50x50
                  )?.url
                }
              />
              <BrandedContainerDrawerContent
                isNestedDrawer={isNestedDrawer}
                isFormDisabled={!isFormEnabled}
                onDirtyChange={setIsDirty}
                topicId={topic.id}
                onBrandChange={onBrandChange}
                activityLogData={activityLogData}
                actions={actions}
                handleClose={handleClose}
              />
            </>
          )}
        </>
      )}
    </StyledDrawer>
  );
};
