import {
  Link,
  useNavigate,
  useLocation,
  Routes,
  Route,
} from 'react-router-dom';
import React, { useCallback, useEffect, useMemo } from 'react';
import { DataList } from '../../components/common/data-list/data-list.component';
import {
  AssignEventsDocument,
  AssignEventsMutationResult,
  AssignEventsMutationVariables,
  AssignEventsToBcInput,
  BrandedContainer,
  Event,
  GetBrandedContainersForEventAssignmentDocument,
} from '../../resolver.types';
import { Column } from '../../components/common/data-list/types';
import useBrandedContainerColumns from '../../hooks/use-branded-container-columns';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal } from 'antd';
import styled from 'styled-components';
import { TopicDropdown } from '../../components/filters/inputs/branded-container/topic-dropdown/topic-dropdown.component';
import { RowSelectionAction } from '../../components/common/data-list/hooks/use-selection';
import { noop } from '../../utils/text';
import { routes } from '../../route-urls';
import { useMutation } from '@apollo/react-hooks';
import { InputError } from 'yggdrasil-shared/domain/error';
import BrandedContainerDetailsDrawer from '../../components/branded-container/form-steps/branded-containers-step/branded-containers-details-drawer.component';

const StyledModal = styled(Modal)`
  &.ant-modal {
    min-width: 900px;
    max-width: 70vw;

    .ant-modal-body {
      padding: 0;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  .button-container {
    flex: 1;
    text-align: right;
    margin-right: 30px;
  }
`;

const AssignEventsToBcPage = () => {
  const { state } = useLocation() as {
    state: { events?: Event[]; from?: string } | undefined;
  };
  const navigate = useNavigate();

  const closeDetailsOpen = useCallback(() => {
    navigate(routes.eventsAssign(), { state });
  }, [navigate, state]);

  const goToDetailView = React.useCallback(
    (relatedBC: BrandedContainer) => {
      navigate(routes.eventAssignDetailView(relatedBC.id), { state });
    },
    [navigate, state]
  );

  const openBrandedContainerDetails = React.useCallback(
    (record: BrandedContainer) => {
      goToDetailView(record);
    },
    [goToDetailView]
  );

  const baseColumns = useBrandedContainerColumns({
    showBrands: true,
    onClick: openBrandedContainerDetails,
  });

  const [mutate, { loading, error }] = useMutation<
    AssignEventsMutationResult['data'],
    AssignEventsMutationVariables
  >(AssignEventsDocument, {
    refetchQueries: ['GetEvents'],
  });

  const columns: Array<Column<BrandedContainer>> = useMemo(() => {
    return baseColumns.length > 2 ? [baseColumns[0], baseColumns[2]] : [];
  }, [baseColumns]);

  const handleCancel = useCallback(() => {
    navigate(routes.events());
  }, [navigate]);

  const actions: Array<RowSelectionAction<BrandedContainer>> = useMemo(
    () => [
      {
        icon: <PlusOutlined />,
        label: loading ? 'Assigning...' : 'Assign',
        onClick: async (brandedContainers) => {
          const input: AssignEventsToBcInput = {
            eventIDs: state!.events!.map((event) => event.id),
            brandedContainerIDs: brandedContainers.map(
              (brandedContainer) => brandedContainer.id
            ),
          };

          const { data } = await mutate({
            variables: {
              input,
            },
          });

          if (data?.assignEvents?.length) {
            navigate(routes.events());

            message.success('Events assigned!');
          }
        },
        type: 'primary',
        loading,
      },
    ],
    [loading, mutate, state, navigate]
  );

  const actionLabelProvider = useCallback((count: number) => {
    return (
      <span>
        <strong>{count}</strong>{' '}
        {noop(count, 'branded container', 'branded containers')} selected
      </span>
    );
  }, []);

  useEffect(() => {
    if (!state?.events?.length) {
      navigate(routes.events());
    }
  }, [navigate, state]);

  useEffect(() => {
    if (error) {
      Modal.error({
        title: 'Assign events error',
        content: (
          <>
            <p style={{ whiteSpace: 'pre-line' }}>{error.message}</p>
            {'details' in error && (
              <ul>
                {Object.values((error as InputError).details || []).map(
                  ({ message, context: { key } }: any) => (
                    <li key={key}>{message}</li>
                  )
                )}
              </ul>
            )}
          </>
        ),
      });
    }
  }, [error]);

  return (
    <>
      <Routes>
        <Route
          path={`branded-containers/:id/*`}
          element={
            <BrandedContainerDetailsDrawer
              handleClose={closeDetailsOpen}
              width={960}
              noActions
            />
          }
        />
      </Routes>
      <StyledModal
        width="900px"
        className="compatible-row"
        onCancel={handleCancel}
        destroyOnClose={true} // ✅ Prevents hidden elements from keeping focus
        maskClosable={true} // ✅ Click outside to close
        keyboard={true} // ✅ Allows Esc to close
        visible
        title={
          <Title>
            <span>Assign events</span>
            <div className="button-container">
              <Link
                to={{
                  pathname: routes.selectPromotionItems('events'),
                }}
                state={state}
              >
                <Button icon={<PlusOutlined />}>
                  Create new branded container
                </Button>
              </Link>
            </div>
          </Title>
        }
        footer={null}
      >
        <DataList<BrandedContainer>
          rowSelectionPosition="sticky"
          useRowSelection
          selectionLabelProvider={actionLabelProvider}
          rowSelectionColPush={0}
          rowSelectionActions={actions}
          filters={[
            <Col key={1} xxl={16} xl={16} md={16} xs={24}>
              <TopicDropdown
                name="topic"
                placeholder="Branded container topic..."
              />
            </Col>,
          ]}
          columns={columns}
          query={GetBrandedContainersForEventAssignmentDocument}
          dataType="brandedContainersForAssignment"
        />
      </StyledModal>
    </>
  );
};

export default AssignEventsToBcPage;
